import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~4],
		"/(app)/auth/forgot-password": [~5],
		"/(app)/auth/login": [~6],
		"/(app)/auth/new-password": [~7],
		"/(app)/auth/register": [~8],
		"/(app)/auth/verify-otp": [~9],
		"/(dashboard)/homee": [14,[2]],
		"/(dashboard)/homee/[slug]": [15,[2]],
		"/(dashboard)/home": [~10,[2]],
		"/(dashboard)/home/all-merchants": [~11,[2]],
		"/(dashboard)/home/all-offers": [~12,[2]],
		"/(dashboard)/home/all-offers/[slug]": [~13,[2]],
		"/(dashboard)/menu": [16,[2]],
		"/(menu)/menu/categories/[slug]": [~35],
		"/(menu)/menu/categories/[slug]/cart": [37],
		"/(menu)/menu/categories/[slug]/view-orders": [38],
		"/(menu)/menu/categories/[slug]/[slug]": [36],
		"/(dashboard)/offer": [17,[2]],
		"/(dashboard)/points": [~18,[2]],
		"/(dashboard)/points/[pointsDetails]": [~19,[2]],
		"/privacy-policy": [39],
		"/(dashboard)/profile": [~20,[2]],
		"/(dashboard)/profile/contact-us": [~21],
		"/(dashboard)/profile/faq": [22,[2]],
		"/(dashboard)/profile/my-account": [~23,[2]],
		"/(dashboard)/profile/my-account/save": [~24,[2]],
		"/(dashboard)/profile/privacy-policy": [25,[2]],
		"/(dashboard)/profile/refer-a-friend": [~26,[2]],
		"/(dashboard)/profile/terms-condition": [27,[2]],
		"/(dashboard)/rewards": [28,[2,3]],
		"/(dashboard)/rewards/active": [~29,[2,3]],
		"/(dashboard)/rewards/active/[slug]": [~30,[2]],
		"/(dashboard)/rewards/past": [~31,[2,3]],
		"/(dashboard)/rewards/past/[slug]": [~32,[2]],
		"/(dashboard)/scan": [33,[2]],
		"/terms-condition": [40],
		"/(dashboard)/test": [34,[2]],
		"/ui": [41]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';